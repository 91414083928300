import http from "@/http";
import './index.less';

const extensionsVideo = {

    'webm': 'video/webm',
    'mp4': 'video/mp4',
    'wmv': 'video/x-ms-wmv',
    'mov': 'video/quicktime'
};

export default {

    data() {
        return {

            loading: true,
            file: null,
            loadingFile: true,
        }
    },
    created() {
        
        this.load();
    },
    computed: {

        session() {

            return this.$store.getters['session/user'];
        }
    },
    methods: {

        load() {

            http.get('painel/vistorias.visualizarFoto', { params: {

                vistoria_id: parseInt(this.$route.params.id), 
                uuid: this.$route.params.uuid

            }}).then(({ data }) => {

                this.file = data

            }).catch(err => {

                this.$error({

                    title: 'Desculpe, houve um erro!',
                    content: 'Tente novamente mais tarde.',
                });

            }).finally(() => this.loading = false);
        },
        onFileLoaded(e) {

            if(e) console.log(e);
            this.loadingFile = false;
        },
        renderPhotoVideo(){

            if(this.loading) return;

            if (this.file.extensao && Object.keys(extensionsVideo).includes(this.file.extensao.toLowerCase())){

                return (
                    <video controls oncanplay={this.onFileLoaded} onStalled={this.onFileLoaded}>
                        <source src={this.file.url} type={extensionsVideo[this.file.extensao]} />
                    </video>
                )
            }

            return (
                <img
                    src={this.file.url}
                    alt="foto"
                    onLoad={this.onFileLoaded}
                    onError={this.onFileLoaded}
                />
            )
        }
    },
    render() {

        return (
            <a-spin spinning={this.loading || this.loadingFile}>
                <div class="view view-body-scrollable">
                    <div class="view-header">
                        <div class="view-header-body" style='padding: 16px 12px'>
                            <div class="view-header-start">
                                <g-icon icon={['fas', 'photo-video']} />
                                <h1 style='font-size: 26px'>Visualização foto/video</h1>
                            </div>
                            <div class="view-header-end">
                                <a-button onClick={() => this.$router.push({name: 'galeria', params: {id: this.$route.params.id}})}>
                                    <g-icon icon={['fas', 'arrow-left']} fixedWidth/>
                                    <span>Voltar</span>
                                </a-button>
                            </div>
                        </div>
                    </div>

                    <div class='view-file'>
                            {
                                this.file && (

                                    <div class='view-file-content'>
                                        <a-row>
                                            <a-col {...{ props: { sm: 24, lg: 8 } }}>
                                                <h1>{this.file.pasta === 'vistorias_mapas' ? 'Mapa' : 'Ambiente'}: {this.file.legenda.split('/')[0]}</h1>
                                                <h2 v-show={this.file.pasta === 'vistorias_itens'}>Item: {this.file.legenda.split('/')[1]}</h2>
                                                <h3>Título: {this.file.titulo || 'Não informado'}</h3>
                                                <h4>Descrição: {this.file.descricao || 'Não informado'}</h4>
                                            </a-col>

                                            <a-col {...{ props: { sm: 24, lg: 16 } }}>
                                                <div class='view-file-content-display'>
                                                    {this.renderPhotoVideo()}
                                                </div>
                                            </a-col>
                                        </a-row>
                                    </div>
                                )
                            }
                    </div>
                </div>
            </a-spin>
        )
    }
}
